<template>
<v-dialog v-model="dialog" width="650">
    <v-card>
        <bar-title-dialog title="Editar item de venda " @close="dialog=false" />
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="2" class="mt-4">
                    <v-avatar  style="border:1px solid lightgray" size="80" tile>
                      <img width="80" src="@/assets/images/pages/defaultProduct.jpg" alt="">
                    </v-avatar>
                </v-col>
                <v-col cols="7">
                    <p class=" mt-4 text_item">{{item.description}}</p>
                </v-col>
                <v-col>
                    <p class="ml-3 mt-4 text-right text_item">R$ {{ formatMoney(item.sale_price) }}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <vuetify-money v-model="item.sale_price" outlined label="Preço" />
                </v-col>
                <v-col>
                    <vuetify-money v-model="item.pivot.quantity" outlined label="Quantidade" :options="{ locale: 'pt-BR' ,  length: 11, precision: 0 }" />

                </v-col>
                <v-col cols="3">
                    <v-btn  @click="minusQtd()" color="secondary"  fab  class="">
                        <v-icon >{{icons.mdiMinus}}</v-icon>
                    </v-btn>
                    <v-btn @click="plusQtd()" color="primary" fab   class="ml-4 ">
                        <v-icon >{{icons.mdiPlus}}</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <!-- <input-search-employee :return_object="true" v-model="item.employee"></input-search-employee> -->
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-row>
                <v-col class="text-left">
                    <v-btn x-large color="error" text @click="removeItem()">Remover item</v-btn>
                </v-col>
                <v-col class="text-right">
                    <v-btn x-large text @click="dialog=false">Fechar</v-btn>
                    <v-btn @click="save()" x-large color="primary" class="ml-2">salvar</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import BarTitleDialog from '@/components/BarTitleDialog.vue'
// import InputSearchEmployee from '@/components/InputSearchEmployee.vue'
import {
    mdiPlus,
    mdiMinus,

} from '@mdi/js'
import {
    mapGetters,
    mapMutations
} from 'vuex'
export default {
    props: {
        value: {
            type: Boolean
        },
        index: {
            type: Number
        }
    },
    components: {
        BarTitleDialog,
      // InputSearchEmployee,
    },
    data: () => ({
        item: {pivot:{quantity:0}},
        dialog: false,
        toggle_exclusive: null,
        icons: {
            mdiPlus,
            mdiMinus
        }
    }),
    watch: {
        value(val) {
            this.dialog = val
            if (val) {
                this.item = Object.assign({},this.getItemsCheckout[this.index])

            }
        },
        dialog(val) {
            this.$emit('input', val)
        }
    },
    computed: {
        ...mapGetters('checkout', ['getItemsCheckout']),

    },
    methods: {
        ...mapMutations('checkout', ['setCheckout']),
        removeItem() {
            let items = this.getItemsCheckout
            items.splice(this.index, 1)
            this.setCheckout(items)
            this.dialog = false
        },
        minusQtd(){
          if(this.item.pivot.quantity > 0){
            this.item.pivot.quantity -= 1
          }

        },
        plusQtd(){
           this.item.pivot.quantity += 1
        },
        formatMoney(value) {
          if(value){
            return value.toLocaleString('pt-br', {
                minimumFractionDigits: 2
            })
          }
        },
        save(){
          let items = this.getItemsCheckout
          items[this.index] = this.item
          this.setCheckout(items)
          this.dialog = false
        }
    },

}
</script>

<style scoped>
.btn_plus_minus {
    border-radius: 5px;
}

.text_item {
    color: black;
    font-size: 17px;
}
</style>
