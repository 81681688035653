<template>
<div class="d-flex">
    <v-row >
        <v-col cols="7" v-if="getStage == 'payment'">
            <Payment />
        </v-col>
        <v-col v-if="getStage == 'multiple_payment_methods'">
            <MultiplePaymentMethods  />
        </v-col>
        <v-col v-if="getStage == 'separate_payment'">
            <SeparatePayment  />
        </v-col>
        <v-col class=" " cols="5">
            <v-card  elevation="0" outlined rounded="1" class="card_checkout fill-height ">
                <Order></Order>
            </v-card>
        </v-col>
    </v-row>

</div>
</template>

<script>
import Payment from './Payment.vue'
import Order from './Order.vue'
import {
    mapGetters
} from 'vuex'

import MultiplePaymentMethods from './MultiplePaymentMethods.vue'
import SeparatePayment from './SeparatePayment.vue'

export default {
    components: {
        Payment,
        Order,
        MultiplePaymentMethods,
        SeparatePayment
    },
    data: () => ({

    }),
    computed: {
        ...mapGetters('checkout', ['getStage']),
    },
    methods: {

    }
}
</script>
