<template>
<v-dialog v-model="value" max-width="500px">

    <v-card>
      <BarTitleDialog title="Pagamento em dinheiro" @close="$emit('input', false)"/>

        <v-card-text>
            <v-row>
                <v-col class="text-left text">
                    Valor a pagar
                </v-col>
                <v-col class="text-right text">
                    R$ {{ formatMoney(remainingValueSeparate) }}
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <vuetify-money :error-messages="validPayValue" v-model="payValue" outlined label="Valor pago pelo cliente" />
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="change">{{ labelChange }}</v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card outlined class="card" @click="applyValue(auxValues[0])">
                        <v-card-text>
                            <div class="text-center text" @click="applyValue(auxValues[0])">{{ formatMoney(auxValues[0]) }}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card outlined class="card" @click="applyValue(auxValues[1])">
                        <v-card-text>
                            <div class="text-center text" @click="applyValue(auxValues[1])">{{ formatMoney(auxValues[1]) }}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card outlined class="card" @click="applyValue(auxValues[2])">
                        <v-card-text>
                            <div class="text-center text">{{ formatMoney(auxValues[2]) }}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card outlined class="card" @click="applyValue(auxValues[3])">
                        <v-card-text>
                            <div class="text-center text">{{ formatMoney(auxValues[3]) }}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card outlined class="card" @click="applyValue(auxValues[4])">
                        <v-card-text>
                            <div class="text-center text">{{ formatMoney(auxValues[4]) }}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card outlined class="card" @click="applyValue(auxValues[5])">
                        <v-card-text>
                            <div class="text-center text">{{ formatMoney(auxValues[5]) }}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

        </v-card-text>
        <v-card-actions>
          <v-row>
                <v-col class="text-right">
                    <v-btn text x-large @click="close()">Cancelar</v-btn>
                    <v-btn class="ml-2" color="primary" @click="addPayment()" x-large>Receber dinheiro</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import {
    mdiWindowClose,
} from '@mdi/js'
import {
    mapActions,
    mapGetters,
    mapMutations
} from 'vuex';


import BarTitleDialog from '@/components/BarTitleDialog.vue';
export default {
    props: {
        value: {
            type: Boolean
        }
    },
    components: {
      BarTitleDialog
    },
    watch: {
        value(val) {
            if (val) {
                this.generateValues()
                this.payValue = this.remainingValueSeparate
            }
        },
        // payValue(val){
        //   this.change = val - this.getTotal
        //   if(this.change < 0){
        //     this.valueValid = true
        //   }else{
        //     this.valueValid = false
        //   }
        // }
    },
    data: () => ({
        valueValid: [],
        payValue: 0,
        change: 0,
        loading: false,
        auxValues: [],
        data: {
            deposit: 0,
            obs: ''
        },

        icons: {
            mdiWindowClose,
        },
    }),
    created() {

    },
    computed: {
        ...mapGetters('checkout', ['getTotalToPay', 'remainingValueSeparate']),
        validPayValue() {
            if (this.payValue <= 0) {
                return this.valueValid = ['Quantia deve ser 0.01 ou superior']
            }

            return []
        },
        labelChange() {

            if (this.change <= 0) {
                return 'Sem troco'
            }
            return 'Troco: ' + this.formatMoney(this.change)
        }

    },
    methods: {
        ...mapMutations('checkout', ['addPaymentMethodSeparate', 'setStage']),

        applyValue(val) {
            this.payValue = val
            //this.change = val - this.getTotal
        },
        close() {
            this.$emit('input', false)
        },
        generateValues() {
            let auxValue = this.getTotalToPay
            //primeira tecla
            this.auxValues[0] = auxValue
            //segunda tecla
            auxValue = Math.ceil(auxValue / 5) * 5
            this.auxValues[1] = auxValue
            //terceira tecla
            this.auxValues[2] = auxValue * 2
            //quarto tecla
            this.auxValues[3] = auxValue * 3
            //quita tecla
            this.auxValues[4] = auxValue * 4
            //sexta
            this.auxValues[5] = this.auxValues[4] * 2
        },
        addPayment() {
            const paymentMethod = {
                method: 'money',
                value: this.payValue


            }
            this.addPaymentMethodSeparate(paymentMethod)
            this.close()

            // if (this.remainingValue > 0) { // se existe valor restante vai para a pagina de mutiplas formas de pagamento
            //     this.setStage('multiple_payment_methods')
            // }

        },
        formatMoney(value) {
            if (value != null) {
                return value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
        },
    }

}
</script>

<style scoped>
.text {
    color: black;
    font-size: large;
}

.card {
    height: 67px;
    cursor: pointer;
    border: solid 1px black;
}

.change {
    color: blueviolet;
    font-size: medium;
}

.card:hover {
    background: rgb(248, 248, 248);
}
</style>
