<template>
<div>
    <v-row>
        <v-col cols='1'>
            <v-btn outlined icon x-large @click="showPayment()">
                <v-icon> {{ icons.mdiChevronLeft }}</v-icon>
            </v-btn>
        </v-col>
        <v-col>
            <span class="title">
                <h2>Mútiplas formas de pagamento</h2>
            </span>
        </v-col>
        <!-- <v-col cols="1">
            <v-btn outlined icon color="primary" x-large>
                <v-icon> {{ icons.mdiCog }}</v-icon>
            </v-btn>
        </v-col> -->
    </v-row>

    <!-- PAGAMENTOS -->
    <v-row v-for="payment in getpaymentMethods" :key="payment.method">
        <v-col>
            <v-card>
                <v-card-text v-if="payment.method == 'credit_card'">
                    <v-row>
                        <v-col>
                            <v-icon color="primary" large> {{ icons.mdiCreditCard }}</v-icon>
                            <span class="text mt-4"> Cartão de Crédito</span>
                        </v-col>
                        <v-col class="text-right">
                            <span class="text mt-4"> R$ {{formatMoney(payment.value)}}</span>
                            <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                <v-icon color="error"> {{ icons.mdiTrashCanOutline }}</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>

                 <v-card-text v-if="payment.method == 'debit_card'">
                    <v-row>
                        <v-col>
                            <v-icon color="success" large> {{ icons.mdiCreditCard }}</v-icon>
                            <span class="text mt-4"> Cartão de Débito</span>
                        </v-col>
                        <v-col class="text-right">
                            <span class="text mt-4"> R$ {{formatMoney(payment.value)}}</span>
                            <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                <v-icon color="error"> {{ icons.mdiTrashCanOutline }}</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text v-if="payment.method == 'money'">
                    <v-row>
                        <v-col>
                            <v-icon color="success" large> {{ icons.mdiCashUsd }}</v-icon>
                            <span class="text mt-4"> Dinheiro</span>
                        </v-col>
                        <v-col class="text-right">
                            <span class="text mt-4"> R$ {{formatMoney(payment.value)}}</span>
                            <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                <v-icon color="error"> {{ icons.mdiTrashCanOutline }}</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-if="payment.method == 'b2b'">
                    <v-row>
                        <v-col>
                            <v-icon color="warning" large> {{ icons.mdiHandshakeOutline }}</v-icon>
                            <span class="text mt-4"> B2B</span>
                        </v-col>
                        <v-col class="text-right">
                            <span class="text mt-4"> R$ {{ formatMoney(payment.value) }}</span>
                            <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                <v-icon color="error"> {{ icons.mdiTrashCanOutline }}</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text v-if="payment.method == 'pix'">
                    <v-row>
                        <div class="log_pix mt-2 ml-3">
                                    <img width="40" src="@/assets/images/logos/logo-pix.png" alt="">
                                </div>
                                <div class="text_method_pix mt-4 ml-1" style="font-size:large">
                                    <span> Pix</span>
                                </div>
                        <v-col class="text-right">

                            <span class="text "> R$ {{ formatMoney(payment.value)}}</span>
                            <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                <v-icon color="error"> {{ icons.mdiTrashCanOutline }}</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-card-text>
               

                 <v-card-text v-if="payment.method == 'other'">
                    <v-row>
                        <v-col>
                            <v-icon color="error" large> {{ icons.mdiCashMultiple }}</v-icon>
                            <span class="text mt-4"> Outros</span>
                        </v-col>
                        <v-col class="text-right">
                            <span class="text "> R$ {{ formatMoney(payment.value)}}</span>
                            <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                <v-icon color="error"> {{ icons.mdiTrashCanOutline }}</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-card-text>

            </v-card>
        </v-col>
    </v-row>
    <v-expand-transition>
        <v-row v-if="remainingValue != 0">
            <v-col class="text-center mt-6">
                <v-btn @click="dialog = true" elevation="0" class="btn-text" x-large color="primary">
                    <v-icon>{{icons.mdiPlusCircleOutline}}</v-icon> &nbsp Adicionar Forma de pagamento - &nbsp <b> R$ {{formatMoney(remainingValue)}} a pagar</b>
                </v-btn>
            </v-col>
        </v-row>
    </v-expand-transition>
    <!-- MODAL ESCOLHER TIPO DE PAGAMENTO -->
    <v-dialog v-model="dialog" max-width="478px">
        <v-card>

            <BarTitleDialog :margin_bottom="false" title="Escolher pagamento" @close="dialog = false"></BarTitleDialog>
            <v-row no-gutters>
                <v-col>
                    <v-card class="btn_payment_methods" tile block elevation="0" x-large @click="showModalPaymentMoney()">
                        <v-row>
                            <v-col>
                                <v-icon color="success" x-large> {{ icons.mdiCashUsd }}</v-icon><span class="text_methods">&nbspDinheiro</span>
                            </v-col>
                            <v-col class="text-right pt-4">
                                <v-icon> {{ icons.mdiChevronRight }}</v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-card class="btn_payment_methods" tile block elevation="0" x-large @click="showModalPix()">
                        <v-row>
                            <v-col>
                                <div class="log_pix">
                                    <img width="40" src="@/assets/images/logos/logo-pix.png" alt="">
                                </div>
                                <div class="text_method_pix">
                                    <span>&nbspPix</span>
                                </div>
                            </v-col>
                            <v-col class="text-right pt-4">
                                <v-icon> {{ icons.mdiChevronRight }}</v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-card class="btn_payment_methods" tile block elevation="0" x-large @click="showModalCreditCard()">
                        <v-row>
                            <v-col>
                                <v-icon color="primary" x-large> {{ icons.mdiCreditCard }}</v-icon><span class="text_methods">&nbspCartão Crédito</span>
                            </v-col>
                            <v-col class="text-right pt-4">
                                <v-icon> {{ icons.mdiChevronRight }}</v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            
            <v-row no-gutters>
                <v-col>
                    <v-card class="btn_payment_methods" tile block elevation="0" x-large @click="showModalDebitCard()">
                        <v-row>
                            <v-col>
                                <v-icon color="success" x-large> {{ icons.mdiCreditCard }}</v-icon><span class="text_methods">&nbspCartão Débito</span>
                            </v-col>
                            <v-col class="text-right pt-4">
                                <v-icon> {{ icons.mdiChevronRight }}</v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-card class="btn_payment_methods" tile block elevation="0" x-large @click="showModalB2b()">
                        <v-row>
                            <v-col>
                                <v-icon color="warning" x-large> {{ icons.mdiHandshakeOutline }}</v-icon><span class="text_methods">&nbspB2B</span>
                            </v-col>
                            <v-col class="text-right pt-4">
                                <v-icon> {{ icons.mdiChevronRight }}</v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <!-- <v-row no-gutters>
                <v-col>
                    <v-card class="btn_payment_methods" tile block elevation="0" x-large @click="showModalOther()">
                        <v-row>
                            <v-col>
                                <v-icon color="warning" x-large> {{ icons.mdiCurrencyUsd }}</v-icon><span class="text_methods">Outros</span>
                            </v-col>
                            <v-col class="text-right pt-4">
                                <v-icon> {{ icons.mdiChevronRight }}</v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
        </v-card>
    </v-dialog>
    <!-- END MODAL ESCOLHER TIPO DE PAGAMENTO -->

    <!-- MODAL PAGAMENTO OUTROS  -->
    <v-dialog v-model="dailogOther" max-width="500px">
        <v-card>
            <v-row no-gutters>
                <v-col class="bar_top"></v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="2"></v-col>
                <v-col class="text-center text-h6">Escolher tipo de pagamento</v-col>
                <v-col cols="2" class="text-right">
                    <v-btn icon @click="dailogOther = false">
                        <v-icon> {{ icons.mdiWindowClose }} </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card-text>
                <v-row>
                    <v-col class="text-left text">
                        Valor a pagar
                    </v-col>
                    <v-col class="text-right text">
                        R$ {{ formatMoney(remainingValue) }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <vuetify-money :error-messages="validPayValueOther" v-model="payValueOther" outlined label="Valor pago pelo cliente" />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="change">{{ calcChange }}</v-col>
                </v-row>
                <v-row>
                    <v-col class="text-right">
                        <v-btn text large @click="dailogOther = false">Cancelar</v-btn>
                        <v-btn @click="addPayment()" class="ml-2" color="primary" large :disabled="validPayValueOther">Receber dinheiro</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- END MODAL ESCOLHER VALOR FORMA OUTROS  -->

    <!-- MODAL PAGAMENTO DINHEIRO -->
    <ModalPaymentMoney v-model="modalPaymentMoney"></ModalPaymentMoney>
    <!-- END MODAL PAGAMENTO DINHEIRO -->

    <!-- MODAL PAGAMENTO PIX -->
    <ModalPaymentPix v-model="modalPaymentPix"></ModalPaymentPix>
    <!-- END MODAL PAGAMENTO PIX -->

    <!-- MODAL PAGAMENTO CREDIT CARD -->
    <ModalPaymentCreditCard v-model="modalPaymentCreditCard"></ModalPaymentCreditCard>
    <!-- END MODAL PAGAMENTO CREDIT CARD -->

    <!-- MODAL PAGAMENTO DEBIT CARD -->
    <ModalPaymentDebitCard v-model="modalPaymentDebitCard"></ModalPaymentDebitCard>
    <!-- END MODAL PAGAMENTO CREDIT CARD -->

    <!-- MODAL PAGAMENTO B2B -->
    <ModalPaymentB2B v-model="modalPaymentB2b"></ModalPaymentB2B>
    <!-- END MODAL PAGAMENTO B2B -->

</div>
</template>

<script>
import ModalPaymentMoney from './ModalPaymentMoney.vue'
import ModalPaymentB2B from './ModalPaymentB2B.vue'
import ModalPaymentPix from './ModalPaymentPix.vue'
import ModalPaymentCreditCard from './ModalPaymentCreditCard.vue'
import ModalPaymentDebitCard from './ModalPaymentDebitCard.vue'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import {
  mdiCog,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCashUsd,
  mdiCreditCard,
  mdiCashMultiple,
  mdiCurrencyUsd,
  mdiPlusCircleOutline,
  mdiWindowClose,
  mdiTrashCanOutline,
  mdiHandshakeOutline,
} from '@mdi/js'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    ModalPaymentMoney,
    BarTitleDialog,
    ModalPaymentPix,
    ModalPaymentCreditCard,
    ModalPaymentDebitCard,
    ModalPaymentB2B,
  },
  data() {
    return {
      modalPaymentB2b: false,
      modalPaymentCreditCard: false,
      modalPaymentDebitCard: false,
      tabs: null,
      dialog: false,
      dailogOther: false,
      payValueOther: 0,
      valueValid: [],
      modalPaymentMoney: false,
      modalPaymentPix: false,
      icons: {
        mdiPlusCircleOutline,
        mdiChevronLeft,
        mdiCog,
        mdiCashUsd,
        mdiCreditCard,
        mdiCashMultiple,
        mdiCurrencyUsd,
        mdiChevronRight,
        mdiWindowClose,
        mdiTrashCanOutline,
        mdiHandshakeOutline,
      },
    }
  },
  watch: {
    dialog(val) {
      if (val) {
        this.payValueOther = this.remainingValue
      }
    },
  },
  computed: {
    ...mapGetters('checkout', ['remainingValue', 'getpaymentMethods']),
    calcChange() {
      let result = this.payValueOther - this.remainingValue

      if (Math.sign(result) == -1) {
        //se = o sinal do numero é negativo
        result = result * -1 //invert o sinal
      }

      //se o valor for maior
      if (this.payValueOther < this.remainingValue) {
        return 'Valor restante a ser pago : R$ ' + this.formatMoney(result)
      }
      //se o valor for igual
      if (this.payValueOther == this.remainingValue) {
        return 'Sem troco'
      }
      //se o valor for menor

      if (this.payValueOther > this.remainingValue) {
        return 'R$ ' + this.formatMoney(result) + ' de troco'
      }
    },
    validPayValueOther() {
      if (this.payValueOther <= 0) {
        return (this.valueValid = ['Quantia deve ser 0.01 ou superior'])
      }

      return null
    },
  },
  methods: {
    ...mapMutations('checkout', ['setStage', 'addPaymentMethod', 'removePaymentMethod']),
    showModalDebitCard() {
      this.dialog = false
      this.modalPaymentDebitCard = true
    },
    showModalCreditCard() {
      this.dialog = false
      this.modalPaymentCreditCard = true
    },
    showModalPaymentMoney() {
      this.dialog = false
      this.modalPaymentMoney = true
    },
    showModalOther() {
      this.dialog = false
      this.dailogOther = true
    },
    showModalPix() {
      this.dialog = false
      this.modalPaymentPix = true
    },
    showModalB2b() {
      this.dialog = false
      this.modalPaymentB2b = true
    },
    showGratuity() {
      this.setStage('gratuity')
    },
    showPayment() {
      this.setStage('payment')
    },
    formatMoney(value) {
      return value.toLocaleString('PT-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },
    //metodos dialog other
    addPayment() {
      const payment = {
        method: 'other',
        value: this.payValueOther,
      }

      this.addPaymentMethod(payment)
      this.dailogOther = false
    },

    removePayment(payment) {
      this.removePaymentMethod(payment)
    },
  },
}
</script>

<style scoped>
.log_pix {
  width: 40px;
  float: left;
}

.text_method_pix {
  color: black;
  margin-top: 8px;
}

.card {
  height: 100px;
  cursor: pointer;
}

.card:hover {
  background: rgb(248, 248, 248);
}

.text_card {
  color: black;
  font-size: 16px;
}

.title {
  color: black;
  font-size: 25px;
}

.btn_payment_methods {
  border-top: 1px solid #eeeeee;
  height: 81px;
  width: 478px;
  padding-top: 21px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.btn_payment_methods:hover {
  background: rgb(248, 248, 248);
}

.span {
  margin-top: 10px;
}

.text_methods {
  color: black;
}

.text {
  color: black;
  font-size: large;
}

.change {
  color: blueviolet;
  font-size: medium;
}
</style>
